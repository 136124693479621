import React from "react";
import { socialMediaList } from "../../Data";
import { Player } from "@lottiefiles/react-lottie-player";
import groovyWalkAnimation from "../About/muslim.json";
import Button from "../../Components/Button";
import Section from "../../Components/Section";

export default function Home() {
    return (
        <Section id="home">
          <div className="flex flex-col md:flex-row-reverse justify-between items-center pt-14 gap-8 md:gap-24">
            <div className='w-full flex flex-col justify-center items-center gap-8 md:items-center md:w-1/2' data-aos="fade-left">
              <Player src={groovyWalkAnimation} autoplay loop className="w-[200px] md:w-[270px] xl:w-[500px]" ></Player>
            </div>
            <div className='w-full flex flex-col items-center justify-center md:items-start md:w-1/2' data-aos="fade-right">
              <h1 className="text-3xl md:text-5xl font-bold text-title mb-6">Hi, I'm Damai</h1>
              <p className="text-center md:text-left text-lg font-medium mb-4">I am an enthusiastic software engineer with 2+ years experience. Contributed to 6 projects included food education website, e-commerce, business website, and company profile.</p>
              <div className='flex flex-row gap-4 items-end h-10 mb-6'>
                {socialMediaList.map((socialMedia) => (
                  <i key={socialMedia.label} className={`uil uil-${socialMedia.icon} text-white text-3xl hover:scale-125 duration-300 cursor-pointer rounded-full bg-primary px-2 py-1`} onClick={() => window.open(socialMedia.link)}></i>  
                ))}
              </div>
              <Button className='bg-primary text-white w-fit m-auto md:m-0'
                endIcon={<i className='uil uil-import' />}
                onClick={() => window.open("/Damai Yulianti Siddik_Resume.pdf")}>Learn more</Button>
            </div>
          </div>
        </Section>
    )
}