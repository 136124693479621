import React from "react";
import LineStepper from "../../Components/LineStepper";

import { education } from "./data";
import Section from "../../Components/Section";

export default function Journey() {
    return (
        <Section className="bg-container" id="about">
            <h2 className="text-center font-semibold text-2xl md:text-4xl text-title mb-8">About Me</h2>
            <div className="flex flex-col md:flex-row items-center gap-8" data-aos="fade-up">
                <div className="flex flex-col gap-3 text-left w-full">
                    <div>I am a graduate in economics who has a strong foundation in economic theory, analysis, and research. As I engaged in discussions about the developments in the world during the COVID-19 pandemic, I realized that information technology was rapidly advancing and exerting a significant influence across various sectors.</div>
                    <div>Delving deeper into this field, I discovered a newfound passion for technology and its transformative potential in diverse industries. Recognizing the increasing demand for IT skills, I decided to switch my career in late 2020 and took several bootcamps to support my skills for it. By mid-2021, I joined a company in Singapore, <span className="font-semibold cursor-pointer hover:text-primary" onClick={() => window.open("https://theforest.ai/",)}>Forest Ai Pte. Ltd</span>, as a professional software engineer and was involved in several projects.</div>
                </div>
                <LineStepper data={education} />
            </div>
        </Section>
    )
}