import React from "react";
import { socialMediaList } from "../../Data";

export default function Footer() {
    return (
        <section className="w-full text-sm px-0 mb-12 md:mb-0 py-12 border-container border-t-2 bg-container">
            <footer className="flex flex-col max-w-[1240px] mx-6 xl:m-auto gap-5">
                <div className="text-2xl md:text-4xl text-title font-semibold">Let's connect and create something awesome!</div>
                <div className="flex flex-row items-center justify-between m-auto text-body w-full">
                        <span className="font-semibold text-base">&copy;2024 Damai Y.S</span>
                        <div className="flex flex-row h-full items-center gap-4">
                        {socialMediaList.map((socialMedia) => (
                            <i key={socialMedia.label} className={`uil uil-${socialMedia.icon} text-mono text-2xl hover:scale-125 cursor-pointer bg-letter px-2 py-1 hover:-translate-y-2 duration-300 rounded-full`} onClick={() => window.open(socialMedia.link)}></i>  
                        ))}
                    </div>
                </div>
        </footer>
        </section>

    )
}