import React, { useState } from "react";
import { SocialMediaType, socialMediaTypeMap } from "../../Data";
import { projects } from "./data";
import { snake } from "radash";
import Section from "../../Components/Section";

interface ProjectData {
    [key: string]: string;
}

export default function Portfolio() {
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<any | null>(null);

    return (
        <>
        <Section id="portfolio" className="bg-container">
            <h2 className="text-center font-semibold text-2xl md:text-4xl text-title mb-10">Portfolio</h2>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-8 w-full md:w-fit" data-aos="fade-up">
                {projects.map((data, index) => (
                    <div key={`${data.label} - ${index}`} className="flex flex-col gap-2 relative shadow bg-mono rounded-lg py-2 px-4 cursor-pointer group lg:hover:scale-105 duration-300" 
                        onClick={() => {
                            setDataModal(data)
                            setOpen(!open)
                        }}
                    >
                        <div className="text-sm text-second-lighter group-hover:text-primary group-hover:font-semibold">{data.time}</div>
                        <img alt="animation" src={data.image} className="w-full max-w-[320px] h-fit rounded object-contain bg-second p-2" />
                        <h3 className="text-base font-semibold text-title">{data.label}</h3>
                        <span className="inline-block text-sm">{data.role}</span>
                    </div>
                ))}
            </div>
        </Section>
        {open && dataModal != null && (
            <div className="h-screen w-screen bg-black/80 fixed z-[100] top-0 left-0 flex items-center justify-center">
                <i className="uil uil-times absolute right-2 md:right-10 text-[40px] top-10 cursor-pointer z-[10]
                    text-white" onClick={() => setOpen(!open)} />
                <div className="!h-auto px-6 mb-10 block mx-auto overflow-hidden w-[730px]">
                    <div className="flex flex-col h-full justify-center gap-3 p-6 md:p-8 bg-mono rounded-2xl md:rounded-3xl text-letter">
                    <img src={dataModal.image} alt="company" className="w-full h-auto shadow-modal object-contain rounded-md md:rounded-xl" />
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-base md:text-xl text-left font-semibold">{dataModal.label}</div>
                        <div className="flex flex-row gap-2">
                            {Object.entries(dataModal.platform as ProjectData).map(([key, value]) => {
                                const k = key as SocialMediaType;
                                return (
                                    <i key={key} className={`uil uil-${socialMediaTypeMap[k]} text-primary text-2xl cursor-pointer`} onClick={() => window.open(value)} />
                                )
                            })}
                        </div>
                    </div>
                    <div className="text-sm">{dataModal.desc}</div>
                    <div className="text-sm font-bold">
                        Responsibilities:
                        <ul className="list-disc list-outside font-normal">
                        {dataModal.responsibilities.map((charge: any) => (
                            <li>{charge}</li>
                        ))}
                        </ul>
                        {(dataModal.label === "Canlah" || dataModal.label === "Maple") && (
                            <div className="mt-2 text-sm font-semibold">Note: The server status is unknown. You can see more <a className="text-primary" href={`https://theforest.ai/work/${snake(dataModal.label)}`}>here</a></div>
                        )}
                    </div>
                    <div className="flex flex-row gap-2 flex-wrap items-center mt-2">
                        {dataModal.tech.map((tech: any) => (
                        <div key={tech} className="px-2 py-1 bg-primary rounded-full text-xs text-white">{tech}</div>
                        ))}
                    </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}