export const education = [
    {
        label: "AWS Academy Cloud Developing",
        role: "Amazon Web Services - Digitalent",
        time: "May 24 - June 24",
        link: "https://www.credly.com/badges/36da1601-351e-4328-9d47-9745a06c2190/linked_in_profile",
    },
    {
        label: "Banking IT - Back End",
        role: "Digitalent - Rakamin Academy",
        time: "July 21 - Oct 21",
        link: "https://drive.google.com/file/d/11htxd_BreS2XVI47OcgSNGV9xXewCe5q/view",
    },
    {
        label: "Backend Development",
        role: "Glints Academy powered by Binar Academy",
        time: "Dec 20 - March 21",
        link: "https://drive.google.com/file/d/1ixboKC7K6VyvMxSQ2SzZOHd8Srp6-WY3/view",
    },
    {
        label: "Economics",
        role: "Andalas University",
        time: "Aug 12 - Jan 18",
        link: "https://pddikti.kemdikbud.go.id/detail-mahasiswa/RvUu2zmzvn2EYgQ1v_FrvTWc9c7tIOZUcvmtDaa6CIvghj5r546eAW0x4v6TC2HwZ-ouRA=="
    },
];