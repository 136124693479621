export const socialMediaList = [
    {
      label: 'email',
      icon: 'envelope',
      link: 'mailto:damai.siddik@gmail.com'
    },
    // {
    //   label: 'instagram',
    //   icon: 'instagram',
    //   link: 'https://www.instagram.com/damaisiddik/'
    // },
    {
      label: 'linkedin',
      icon: 'linkedin',
      link: 'https://www.linkedin.com/in/damai-siddik/',
    },
    {
        label: 'github',
        icon: 'github-alt',
        link: 'https://github.com/damai-siddik',
      },
];

export const socialMediaTypeMap = Object.freeze({
  website: 'globe',
  linkedin: 'linkedin',
  instagram: 'instagram',
});

export type SocialMediaType = keyof typeof socialMediaTypeMap;