import React from 'react';
import Header from './Sections/Header';
import Home from './Sections/Home';
import Skills from './Sections/Skills';
import Recommendations from './Sections/Recommendations';
import Footer from './Sections/Footer';
import Journey from './Sections/Journey';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Portfolio from './Sections/Portfolio';

function App() {
  AOS.init({ duration : 2000});

  return (
      <main className=''>
        <Header />
        <Home />
        <Journey />
        <Skills />
        <Portfolio />
        <Recommendations />
        <Footer />
      </main>
  );
}

export default App;
