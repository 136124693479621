import React from "react";
import { twMerge } from "tailwind-merge";

interface SectionProps {
    children: React.ReactNode;
    id: string;
    className?: string | null;
}

export default function Section({id, className, children}: SectionProps): JSX.Element {
    return (
        <section id={id} className={twMerge(className, "pt-8 pb-16 lg:min-h-screen lg:py-0")}>
            <div className="flex flex-col justify-center items-center lg:max-w-[1240px] mx-6 xl:mx-auto min-h-[inherit]" data-aos="fade-up">
                {children}
            </div>
        </section>
    )
}